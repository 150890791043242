import React, { useEffect, useState } from "react"
import { PageProps } from "gatsby"
import styled from "styled-components"
import { toast } from "react-toastify"
import { StaticImage } from "gatsby-plugin-image"
import { Controller, useForm } from "react-hook-form"

import SEO from "../components/seo"
import { GridContainer } from "../components/grid"
import { SimpleTitle } from "../components/heros"
import { Input, TextArea } from "../components/form"
import { PrimaryButton } from "../components/buttons"
import { H2, H4 } from "../components/text"
import { LocationCard, SimpleCard } from "../components/cards"
import { BaseContainer } from "../components/containers"

const HeadOfficeContainer = styled(BaseContainer)`
  grid-column: 1 / span 12;
  display: grid;
  gap: 2rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    grid-column: 1 / span 5;
  }
`

const StyledHeadOfficeTitle = styled(H2)`
  color: ${({ theme }) => theme.colors.beukelaarGreen};
`

const HeadOffice: React.FC = () => {
  return (
    <HeadOfficeContainer>
      <StyledHeadOfficeTitle>Hoofdkantoor</StyledHeadOfficeTitle>
      <div>
        <p>Appelsestraat 3</p>
        <p>3862 PG Appel (Nijkerk)</p>
      </div>
      <p>
        Ons kantoor staat aan de rand van landgoed de Appel, aan het begin van
        de Veluwe in de provincie Gelderland. Het landgoed bestaat uit bos met
        heide en bosmeertjes. Wij genieten dagelijks van de frisse lucht en de
        mooie omgeving waarin we ons bevinden. Wanneer je bij ons op kantoor
        langskomt, zul je merken dat de stress van de stad verdwijnt en je kunt
        genieten van de ontspannen sfeer. Kom gerust langs en ervaar het zelf!
      </p>
    </HeadOfficeContainer>
  )
}

const StyledForm = styled.form`
  grid-column: 1 / span 12;
  display: grid;
  gap: 1rem;
  margin-bottom: 2rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    grid-column: 8 / span 5;
    margin-bottom: 4rem;
  }
`

const SendButton = styled(PrimaryButton)`
  margin-left: auto;
`

const Card = styled(SimpleCard)`
  grid-column: 1 / span 12;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    grid-column: 1 / span 5;
  }
`

const StyledLocationCard = styled(LocationCard)`
  grid-column: 1 / span 12;
`

interface FormData {
  name: string
  phoneNumber: string
  emailAddress: string
  companyName: string
  message: string
}

const ContactPage: React.FC<PageProps> = () => {
  const { handleSubmit, watch, control, reset, formState } = useForm<FormData>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [formsSubmitCount, setFormsSubmitCount] = useState<number>(0)

  useEffect(() => {
    const { errors, submitCount } = formState
    if (submitCount === formsSubmitCount) return
    setFormsSubmitCount(submitCount)

    if (errors.name && errors.name.type === "required") {
      toast.error("Vul alstublieft een geldige naam in")
      return
    }

    if (errors.emailAddress && errors.emailAddress.type === "required") {
      toast.error("Vul alstublieft een geldig e-mailadres in")
      return
    }
  }, [formState])

  const onSubmit = async (formData: FormData) => {
    const url = process.env.GATSBY_SEND_EMAIL_URL!

    setIsLoading(true)

    // Call the API to send an email using the Azure Function
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(formData),
    })

    setIsLoading(false)

    if (response.status === 200) {
      reset({
        name: "",
        emailAddress: "",
        phoneNumber: "",
        companyName: "",
        message: "",
      })
      toast.success("Dankjewel! Wij nemen zo snel mogelijk contact met je op")
    } else {
      toast.error("Helaas ging er iets mis.")
    }
  }

  return (
    <>
      <SEO
        title="Contact"
        description="Nieuwsgierig wat wij voor jou kunnen betekenen? Wij denken graag met je mee."
      />
      <GridContainer>
        <SimpleTitle>Contact</SimpleTitle>
        <HeadOffice />
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          <Controller
            control={control}
            name="name"
            defaultValue={watch("name") ?? ""}
            rules={{ required: true }}
            render={({ field }) => (
              <Input
                onChange={field.onChange}
                aria-invalid={formState.errors.name ? "true" : "false"}
                value={field.value}
                placeholder="Naam *"
              />
            )}
          />
          <Controller
            control={control}
            name="phoneNumber"
            defaultValue={watch("phoneNumber") ?? ""}
            render={({ field }) => (
              <Input
                onChange={field.onChange}
                aria-invalid={formState.errors.phoneNumber ? "true" : "false"}
                value={field.value}
                placeholder="Telefoonnummer"
                type="tel"
              />
            )}
          />
          <Controller
            control={control}
            name="emailAddress"
            defaultValue={watch("emailAddress") ?? ""}
            rules={{ required: true }}
            render={({ field }) => (
              <Input
                onChange={field.onChange}
                aria-invalid={formState.errors.emailAddress ? "true" : "false"}
                value={field.value}
                placeholder="E-mailadres *"
                type="email"
              />
            )}
          />
          <Controller
            control={control}
            name="companyName"
            defaultValue={watch("companyName") ?? ""}
            render={({ field }) => (
              <Input
                onChange={field.onChange}
                aria-invalid={formState.errors.companyName ? "true" : "false"}
                value={field.value}
                placeholder="Bedrijfsnaam"
              />
            )}
          />
          <Controller
            control={control}
            name="message"
            defaultValue={watch("message") ?? ""}
            render={({ field }) => (
              <TextArea
                onChange={field.onChange}
                aria-invalid={formState.errors.message ? "true" : "false"}
                value={field.value}
                placeholder="Bericht"
              />
            )}
          />
          <SendButton disabled={isLoading} type="submit">
            {isLoading ? "Verzenden.." : "Verzenden"}
          </SendButton>
        </StyledForm>
        <Card title="Training &amp; coaching locaties">
          <p>
            Uit de dagelijkse context stappen helpt bij teamdagen, coaching en
            strategische sessies. Of je nu op de goede weg zit, of je bent
            helemaal vastgelopen. Onze mooie locaties helpen organisaties om
            afstand te nemen van de dagelijkse context. Wij verzorgen
            (meerdaagse) trainingen en coaching op de hieronder genoemde
            locatie. Maar natuurlijk komen we ook graag naar jou toe!
          </p>
        </Card>
        <StyledLocationCard
          name="Jachthuis"
          street="Jagdhutte"
          number="1"
          zipCode="54584"
          city="Feusdorf (Duitsland)"
          image={
            <StaticImage
              src="../images/locatie-jachthuis-duitsland.jpg"
              alt="Jachthuis in Duitsland"
              placeholder="blurred"
            />
          }
        >
          In het kleine plaatsje Feusdorf staat te midden van bossen en weiden
          een heel bijzonder huisje. Deze plek is zeer geschikt voor 24uur
          met-sessies. Geen internet, geen nabijgelegen dorpje. Wel een warme
          kachel en de natuur. Dompel je onder in de stilte, een echte donkere
          nacht en de vrijheid om jezelf te zijn.
        </StyledLocationCard>
      </GridContainer>
    </>
  )
}

export default ContactPage
